import moment from 'moment';
import _ from 'lodash';
import { IndexToMonthNames } from 'habitual-analytics/constants/habitual-configs';
import { getInstrumentDetails, getExpiryType } from 'habitual-analytics/utils';

export const parsePlaceOrderTradingSymbol = (tradingSymbolObj) => {
  let formattedTradingSymbol;

  if (tradingSymbolObj.isEquity()) {
    formattedTradingSymbol = `${tradingSymbolObj.toString()}-EQ`;
  } else if (tradingSymbolObj.isFuture()) {
    const {
      expiryYear, expiryMonthSymbol, instrument
    } = getInstrumentDetails(
      tradingSymbolObj.toString()
    );
    formattedTradingSymbol = `${instrument}${expiryYear}${expiryMonthSymbol}FUT`;
  } else {
    const {
      expiryYear, expiryMonthSymbol, expiryDateNumber, instrument
    } = getInstrumentDetails(
      tradingSymbolObj.toString()
    );
    const { strikePrice, optionType } = tradingSymbolObj.getSymbolObject();

    const expiryType = getExpiryType(tradingSymbolObj.toString());

    if (expiryType === 'W') {
      const expiryMonthNumber = moment(expiryMonthSymbol, 'MMM').format('M');
      const expiryMonthNumberFormat = expiryMonthNumber > 9
        ? _.first(IndexToMonthNames[expiryMonthNumber]) : expiryMonthNumber;
      formattedTradingSymbol = `${instrument}${expiryYear}`;
      formattedTradingSymbol += `${expiryMonthNumberFormat}${expiryDateNumber}`;
      formattedTradingSymbol += `${strikePrice}${optionType}`;
    } else {
      formattedTradingSymbol = `${instrument}${expiryYear}`;
      formattedTradingSymbol += `${expiryMonthSymbol}${strikePrice}${optionType}`;
    }
  }
  return formattedTradingSymbol;
};
