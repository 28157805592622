
import moment from 'moment';
import _ from 'lodash';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import { parsePlaceOrderTradingSymbol } from './workmate';
import { PRETTIER_FORMAT_WITH_SECONDS, TIME_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';
import { statusConfigs, transactionTypes } from 'habitual-analytics/constants/habitual-configs';
import {
  convertToNumber,
  getCancellableOrderStatus,
  getTradingSymbolObj,
  isNse,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import { getDefaultProductCode } from '../tradingSymbolParser';
import { isinCode } from 'zeequant-constants';


const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs?.placed?.value;

  switch (_.lowerCase(orderDetail.sts)) {
    case 'cancelled':
      status = statusConfigs?.cancelled?.value;
      break;
    case 'complete':
      status = statusConfigs?.executed?.value;
      break;
    case 'rejected':
      status = statusConfigs?.failed?.value;
      break;
    case isOrderPartiallyExecuted(orderDetail.Status, orderDetail.Qty, orderDetail.Fillshares):
      status = statusConfigs?.partiallyExecuted?.value;
      break;
    case 'trigger pending':
      status = statusConfigs?.pending?.value;
      break;
    default:
      status = statusConfigs?.placed?.value;
      break;
  }

  return status;
};

const parseOrderTYpe = (orderType) => {
  switch (_.toUpper(orderType)) {
    case 'L':
      return 'LIMIT';
    case 'MKT':
      return 'MARKET';
    case 'SL':
      return 'STOP_LIMIT';
    case 'SL-M':
      return 'STOP_MARKET';
    default:
      return 'L';
  }
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exc', '');

  if (!isNse(exchange)) { return null; }

  const symbolType = _.get(orderDetail, 'asTyp', '');
  const tradingSymbol = _.toLower(symbolType) === 'equity'
    ? _.get(orderDetail, 'dpName', '')
    : _.get(orderDetail, 'trdSym', '');
  const transactionType = _.get(orderDetail, 'trsTyp', '');
  const productType = _.get(orderDetail, 'ordTyp', '');
  const productCode = _.get(orderDetail, 'prdCode', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trgPrc', ''));
  const price = convertToNumber(_.get(orderDetail, 'prc', ''));
  const tradedQty = _.get(orderDetail, 'flQty', 0);
  const quantity = Number(_.get(orderDetail, 'ntQty', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'avgPrc', 0));
  const tradedPrice = _.parseInt(targetPrice) !== 0
    ? `${price} / ${targetPrice} trg`
    : _.parseInt(avgPrice) !== 0
      ? avgPrice
      : price;
  const tradedTime = _.get(orderDetail, 'ordTim', '').split(' ')?.[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'rjRsn', '');

  return {
    ...getTradingSymbolObj(tradingSymbol),
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.toLower(transactionType) === 'sell'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder || status === 'pending' ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'ordID', ''),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exc', '');

  if (!isNse(exchange)) { return null; }

  const symbolType = _.get(orderDetail, 'asTyp', '');
  const tradingSymbol = _.toLower(symbolType) === 'equity'
    ? _.get(orderDetail, 'dpName', '')
    : _.get(orderDetail, 'trdSym', '');
  const transactionType = _.get(orderDetail, 'trsTyp', '');
  const productType = _.get(orderDetail, 'ordType', '');
  const productCode = _.get(orderDetail, 'prdCode', '');
  const tradedQuantity = Number(_.get(orderDetail, 'flQty', 0));
  const quantity = Number(_.get(orderDetail, 'qty', 0));
  const status = statusConfigs.executed.label;

  return {
    ...getTradingSymbolObj(tradingSymbol),
    time: moment(_.get(orderDetail, 'ordTim', ''), TIME_FORMAT)?.format(
      PRETTIER_FORMAT_WITH_SECONDS
    ),
    type:
      _.toLower(transactionType) === 'sell'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'flPrc', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exc', '');

  if (!isNse(exchange)) { return null; }

  const symbolType = _.get(orderDetail, 'asTyp', '');
  const tradingSymbol = _.toLower(symbolType) === 'equity'
    ? _.get(orderDetail, 'dpName', '')
    : _.get(orderDetail, 'trdSym', '');
  const ltp = convertToNumber(_.get(orderDetail, 'ltp'));
  const buyAvg = convertToNumber(_.get(orderDetail, 'avgByPrc', 0));
  const sellAvg = convertToNumber(_.get(orderDetail, 'avgSlPrc', 0));
  const orderValue = convertToNumber(_.get(orderDetail, 'prc', 0));
  const qty = Number(_.get(orderDetail, 'ntQty', 0));
  const realisedprofitloss = convertToNumber(_.get(orderDetail, 'rlzPL', 0));
  const profitLoss = qty === 0 ? realisedprofitloss : (ltp - orderValue) * qty;
  const { tradingSymbolObj } = getTradingSymbolObj(tradingSymbol);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'prdCode', ''),
      liveUpdateDetails: { symbol: tradingSymbolObj.toString(), value: 'ltp', key: 'ltp' },
      defaultProductCode: getDefaultProductCode(_.get(orderDetail, 'prdCode', ''), tradingSymbolObj),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type:
        qty !== 0 ? (qty < 0 ? transactionTypes?.sell.value : transactionTypes?.buy?.value) : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exc', '');

  if (!isNse(exchange)) { return null; }

  const tradingSymbol = _.get(orderDetail, 'dpName', '');
  const quantity = _.get(orderDetail, 'cncRmsHdg.qty', 0);
  const t1Qty = _.get(orderDetail, 'cncRmsHdg.t1HQty', 0);
  const ltp = convertToNumber(_.get(orderDetail, 'LTnse', 0));
  const usedQty = _.get(orderDetail, 'cncRmsHdg.usdQty', 0);
  const { tradingSymbolObj } = getTradingSymbolObj(tradingSymbol);
  const isin = _.get(orderDetail, 'isin', '');

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    extraDetails: {
      isin,
      liveUpdateDetails: { symbol: tradingSymbolObj.toString(), value: 'ltp', key: 'LTnse' },
      order: orderDetail,
      quantity,
      t1Qty,
      usedQty,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfig, apiKey) => {
  // completely have to clean up this code
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = parsePlaceOrderTradingSymbol(tradingSymbolObj);
  const formattedExchangeTokenTradingSymbol = parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);
  const exchange = _.get(orderConfig, 'exch', '');

  return {
    trdSym: tradingSymbolObj.isEquity() ? isinCode[tradingSymbolObj?.symbol] : formattedTradingSymbol,
    exc: exchange,
    action: _.get(orderConfig, 'transactionType', '').toUpperCase(),
    dur: 'DAY',
    ordTyp: parseOrderTYpe(_.get(orderConfig, 'prctyp', '')),
    qty: _.get(orderConfig, 'qty', ''),
    dscQty: 0,
    sym: `${symbolId}_${exchange}`,
    mktPro: '',
    lmPrc: _.get(orderConfig, 'price', ''),
    trgPrc: _.get(orderConfig, 'trigPrice', ''),
    prdCode: _.get(orderConfig, 'pCode', ''),
    dtDays: '',
    posSqr: 'N',
    minQty: '',
    ordSrc: 'API',
    vnCode: apiKey,
    rmk: 'InstaOptions',
  };
};

const parseCancelOrder = (orderDetails, orderNo) => {
  const { product } = orderDetails.extraDetails;
  const productDetails = product.split('/');
  return {
    nstOID: orderNo,
    exc: 'NSE',
    prdCode: _.trim(_.get(productDetails, '0', '')),
    ordTyp: _.trim(_.get(productDetails, '1', '')),
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};


const parseMarginCalculator = async (orderDetail) => {
  const { tradingSymbolObj } = orderDetail;
  const exchangeSegment = tradingSymbolObj?.getExchange();
  const formattedExchangeTokenTradingSymbol = parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol, exchangeSegment);
  const exchange = _.get(orderDetail, 'exch', '') || 'NFO';

  const equityConfig = {
    prdCode: _.get(orderDetail, 'pCode', ''),
    qty: _.toString(_.get(orderDetail, 'qty', '')),
    action: _.get(orderDetail, 'transactionType', '').toUpperCase(),
    ordTyp: parseOrderTYpe(_.get(orderDetail, 'prctyp', '')),
    lmPrc: _.get(orderDetail, 'price', ''),
    trgPrc: _.get(orderDetail, 'trigPrice', ''),
    sym: `${symbolId}_${exchange}`,
    slVal: '',
    sqOffVal: '',
    exchange: tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO',
  };

  const derivateConfig = {
    sym: `${symbolId}_${exchange}`,
    qty: _.toString(_.get(orderDetail, 'qty', '')),
    action: _.get(orderDetail, 'transactionType', '').toUpperCase(),
    exchange: tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO',
  };

  return tradingSymbolObj.isEquity() ? equityConfig : derivateConfig;
};

const parseMarginAvailable = () => {
  return {
    prdCode: 'MIS',
    qty: '1',
    action: 'BUY',
    ordTyp: 'MARKET',
    lmPrc: '130.85',
    trgPrc: '130.20',
    slVal: '',
    sqOffVal: '',
    sym: '10666_NSE',
    vnCode: 'demop',
    posSqr: 'N',
    rmk: 'InstaOptions',
    ordSrc: 'API'
  };
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
  parseCancelOrder,
  parseMarginCalculator,
  parseMarginAvailable,
};
