import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import { parseOrderDetails, parsePlaceOrder, parseModifyOrder } from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls/index';

const requestHeaders = () => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;

  return {
    'Content-Type': 'application/json',
    Authorization: brokerClientAccessToken,
  };
};

const getRequestUri = (method, qs = '') => {
  const queryString = qs ? `&${qs}` : '';
  const baseUrl = REST_URLS().BROKER_PROXY;
  return `${baseUrl}?broker=zebu_xts&method=${method}${queryString}`;
};

const isValidAccessToken = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
  };

  return wrappedFetch(
    getRequestUri('validateUser', `clientID=${brokerClientId}`),
    parameters
  )
    .then((response) => response.json())
    .then((res) => res['type'] === 'success')
    .catch(() => {
      return false;
    });
};

const fetchOrderBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
  };

  return wrappedFetch(
    getRequestUri('orderBook', `clientID=${brokerClientId}`),
    parameters
  )
    .then((response) => response.json())
    .then(async (result) => ({
      orderBookDetails: _.compact(
        _.orderBy(
          await parseOrderDetails(result['result'], 'order'),
          ['time'],
          ['desc']
        )
      ),
      headers: orderTableHeaders,
    }));
};

const fetchTradeBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
  };

  return wrappedFetch(
    getRequestUri('tradeBook', `clientID=${brokerClientId}`),
    parameters
  )
    .then((response) => response.json())
    .then(async (result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(result['result'], 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
  };

  return wrappedFetch(
    getRequestUri('positionBook', 'dayOrNet=DayWise'),
    parameters
  )
    .then((response) => response.json())
    .then(async (result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(
              _.get(result, 'result.positionList'),
              'position'
            ),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
  };

  return wrappedFetch(
    getRequestUri('holdings', `clientID=${brokerClientId}`),
    parameters
  )
    .then((response) => response.json())
    .then(async (result) => {
      const holdingData = _.get(result, 'result.RMSHoldings.Holdings', {});
      const objectToArrayConversion = _.values(holdingData);
      return {
        holdingBookDetails: _.compact(
          await parseOrderDetails(objectToArrayConversion, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig);
      const requestBody = JSON.stringify(orderDetails);

      const parameters = {
        method: 'POST',
        headers: requestHeaders(),
        body: requestBody,
      };

      return wrappedFetch(
        getRequestUri('placeOrder', `ClientID=${brokerClientId}`),
        parameters
      )
        .then((response) => response.json())
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ result, type }) =>
      type === 'success' ? result['AppOrderID'] : null
    )
    .filter((value) => value !== null)
    .value();
};

const cancelPlaceOrder = (orderConfig) => {
  const appOrderID = _.get(orderConfig, 'orderNo', '');
  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
  };

  return wrappedFetch(
    getRequestUri('cancelPlaceOrder', `appOrderID=${appOrderID}`),
    parameters
  ).then((response) => response.json());
};

const modifyPlaceOrder = (placeOrderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const modifyOrderConfig = parseModifyOrder(placeOrderConfigs[0]);
  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
    body: JSON.stringify(modifyOrderConfig),
  };

  return wrappedFetch(
    getRequestUri('modifyOrder', `ClientID=${brokerClientId}`),
    parameters
  )
    .then((response) => response.json())
    .then((data) => [data?.result])
    .catch((e) => [e]);
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  cancelPlaceOrder,
  modifyPlaceOrder,
};
