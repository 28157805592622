import { getUpComingExpiryDates } from 'habitual-analytics/dateUtils/expiryDateCalculations';
import _ from 'lodash'; // Ensure lodash is imported
import { getInstrumentConstantLookup } from 'habitual-analytics/constants/habitual-configs';

/**
 * Splits an order based on the freezing quantity of the instrument.
 *
 * @param {Object} order - The order to be split.
 * @returns {Array} An array of split orders.
 */
const sliceOrderByFreezingQuantity = (order) => {
  const { tradingSymbolObj, qty } = order;
  const { symbol, expiryDate } = tradingSymbolObj;

  // Determine the expiry date for equity instruments if not provided
  const filledExpiryDate = expiryDate || getUpComingExpiryDates('M', symbol)[0];

  // Retrieve freezing quantity or default to the entire quantity if not available
  const freezingQuantity = _.get(
    getInstrumentConstantLookup(symbol, filledExpiryDate),
    'freezing_quantity',
    qty
  );

  // Calculate the number of full-sized orders and any remainder
  const fullOrderCount = Math.floor(qty / freezingQuantity);
  const remainingQty = qty % freezingQuantity;

  // Generate full-sized orders
  const slicedOrders = _.isNaN(fullOrderCount)
    ? [order]
    : Array(fullOrderCount).fill({ ...order, qty: freezingQuantity });

  // If there's any remainder, add it as an additional order
  if (remainingQty) {
    slicedOrders.push({ ...order, qty: remainingQty });
  }

  return slicedOrders;
};

/**
 * Processes a list of orders, splitting them based on freezing quantity for index instruments.
 *
 * @param {Array} orders - The list of orders to process.
 * @returns {Array} A list of orders, split as needed.
 */
export const processOrdersByFreezingQuantity = (orders) => {
  return _(orders)
    .flatMap((order) => {
      const { tradingSymbolObj } = order;
      // Only split orders for index instruments
      return tradingSymbolObj?.isIndex()
        ? sliceOrderByFreezingQuantity(order)
        : order;
    })
    .value();
};

// [
//   {
//       "tradingSymbolObj": {
//           "symbol": "BANKNIFTY",
//           "expiryDate": "2024-09-25"
//       },
//       "transactionType": "buy",
//       "ltp": 51595,
//       "actualPrice": 51595,
//       "userId": "XR09644",
//       "exch": "NFO",
//       "pCode": "MIS",
//       "price": 0,
//       "qty": 15,
//       "prctyp": "MKT",
//       "trigPrice": 0,
//       "complexty": "Regular",
//       "type": "",
//       "ret": "DAY",
//       "disCloseQty": 0,
//       "symbol": "BANKNIFTY",
//       "tradingSymbol": "",
//       "prevDayClose": 0
//   }
// ]

// [
//   {
//       "tradingSymbolObj": {
//           "symbol": "SAIL"
//       },
//       "transactionType": "buy",
//       "ltp": 130.46,
//       "actualPrice": 130.46,
//       "userId": "XR09644",
//       "exch": "NSE",
//       "pCode": "MIS",
//       "price": 0,
//       "qty": 1,
//       "prctyp": "MKT",
//       "trigPrice": 0,
//       "complexty": "Regular",
//       "type": "",
//       "ret": "DAY",
//       "disCloseQty": 0,
//       "symbol": "SAIL",
//       "tradingSymbol": "",
//       "prevDayClose": 0
//   }
// ]
