import { isLiveMode } from 'habitual-analytics/utils/index';
import LiveDataAdaptor from './live';
import HistoricDataAdaptor from './historic';

const executeCombinedRequest = (currentRequestQueue, adaptor) => {
  const allInstruments = Array.from(
    new Set(currentRequestQueue.flatMap(({ instruments }) => instruments))
  );

  return adaptor.getData(allInstruments).then((result) => {
    currentRequestQueue.forEach(({ instruments, resolve }) => {
      const filteredMarketData = {};

      instruments.forEach((instrument) => {
        filteredMarketData[instrument] = result.marketData[instrument];
      });

      resolve({
        marketData: filteredMarketData,
      });
    });
  });
};

export const InstrumentDataAdaptor = (() => {
  const getMode = () => (isLiveMode() ? 'live' : 'historic');
  const getAdaptor = () => (
    isLiveMode() ? LiveDataAdaptor : HistoricDataAdaptor
  );
  const requestQueues = {
    live: [],
    historic: [],
  };

  const processRequestQueueConfig = _.debounce((queueType, adaptor) => {
    if (requestQueues[queueType]?.length > 0) {
      executeCombinedRequest(requestQueues[queueType], adaptor);

      requestQueues[queueType] = [];
    }
  }, 500);

  const addToQueue = (queueType, instruments, adaptor) => {
    return new Promise((resolve, reject) => {
      requestQueues[queueType].push({
        instruments: _.split(instruments, ','),
        resolve,
        reject,
      });
      processRequestQueueConfig(queueType, adaptor);
    });
  };

  return {
    getData: async (instruments) => {
      return addToQueue(getMode(), instruments, getAdaptor());
    },
    getLiveData: async (instruments) => {
      return addToQueue('live', instruments, LiveDataAdaptor);
    },
    getHistoricData: async (instruments) => {
      return addToQueue('historic', instruments, HistoricDataAdaptor);
    },
  };
})();
