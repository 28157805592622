import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';

export const DummyEmit = (() => {
  let isAdded = true;
  let intervalId;

  return {
    emit: (ticks) => {
      const newTicks = _.reduce(
        ticks,
        (result, obj, symbol) => {
          result[symbol] = {
            ...obj,
            ltp: isAdded ? obj.ltp + 1 : obj.ltp - 1,
          };

          return result;
        },
        {}
      );
      isAdded = !isAdded;
      ticks = newTicks;
      return newTicks;
    },
    start: (ticker) => {
      if (intervalId) {
        clearInterval(intervalId);
      }

      intervalId = setInterval(() => {
        const newTicks = DummyEmit.emit(ticker.state?.marketData || {});

        ticker.onTick([{
          ...ticker.state,
          marketData: newTicks,
        }]);
      }, 1000);
    },

    stop: () => {
      clearInterval(intervalId);
    },
    runWhenRequired: (ticker) => {
      const has = getDynamicAppConfigs().envs.IS_DUMMY_LIVE_TICKER_RUN === 'true';
      if (!has) {
        return;
      }

      DummyEmit.start(ticker);
    }
  };
})();
