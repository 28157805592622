import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { wrappedFetch } from 'habitual-analytics/api/services/base/index';
import {
  parseOrderDetails,
  parsePlaceOrder,
  parseModifyOrder,
} from './parser';
import {
  holdingTableHeaders,
  orderTableHeaders,
  postitionTableHeaders,
  tradeTableHeaders,
} from './tableHeaders';
import RestEndPoints from './RestEndPoints';

const getRequestPayload = (reqBody = '') => {
  const { brokerClientAccessToken } = getDynamicAppConfigs().brokerConfigs;
  return {
    ...reqBody && { method: 'POST' },
    headers: {
      Authorization: `Bearer ${brokerClientAccessToken}`,
      ...reqBody && { 'Content-Type': 'application/json' },
    },
    ...reqBody && { body: JSON.stringify(reqBody) },
  };
};

const isValidAccessToken = async () => {
  const parameters = getRequestPayload();
  const { PROFILE_PATH } = RestEndPoints;

  return await wrappedFetch(PROFILE_PATH, parameters)
    .then((response) => response.json())
    .then((data) => data.status === 'Ok')
    .catch(() => false);
};

const placeOrder = async (orderDetails) => {
  const { PLACE_ORDER } = RestEndPoints;
  // bluk order placement
  const fetchedOrderDetails = _.map(orderDetails, (orderDetail) => parsePlaceOrder(orderDetail));

  const parameters = getRequestPayload(fetchedOrderDetails);

  return await wrappedFetch(PLACE_ORDER, parameters)
    .then((response) => response.json())
    .then((result) => {
      return _.map(result, (response) => response?.status === 'Ok'
        ? response.result[0]?.orderNo
        : { status: 'failed' });
    })
    .catch((e) => [e]);

};

const fetchOrderBook = async () => {
  const paramaters = getRequestPayload();
  const { GET_ORDER_BOOK } = RestEndPoints;

  return await wrappedFetch(GET_ORDER_BOOK, paramaters)
    .then((res) => res.json())
    .then((data) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(parseOrderDetails(data?.result, 'order'), ['time'], ['desc'])
        ),
        headers: orderTableHeaders,
      };
    })
    .catch((e) => [e]);
};

const fetchTradeBook = async () => {
  const parameters = getRequestPayload();
  const { GET_TRADE_BOOK } = RestEndPoints;

  return await wrappedFetch(GET_TRADE_BOOK, parameters)
    .then((response) => response.json())
    .then((data) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(data?.result, 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = async () => {
  const parameters = getRequestPayload();
  const { GET_POSITION_BOOK } = RestEndPoints;

  return await wrappedFetch(GET_POSITION_BOOK, parameters)
    .then((response) => response.json())
    .then((data) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(
              data?.result,
              'position'
            ),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const parameters = getRequestPayload();
  const { GET_HOLDINGS } = RestEndPoints;
  return wrappedFetch(GET_HOLDINGS, parameters)
    .then((response) => response.json())
    .then((data) => {
      return {
        holdingBookDetails: _.compact(
          parseOrderDetails(data?.result?.[0]?.holdings, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const modifyPlaceOrder = async (placeOrderConfigs) => {
  const { MODIFY_ORDER } = RestEndPoints;
  const parameters = getRequestPayload(await parseModifyOrder(placeOrderConfigs?.[0]));
  return wrappedFetch(MODIFY_ORDER, parameters)
    .then((response) => response.json())
    .then((data) => data?.status === 'Ok' ? [data.result[0]?.orderNo] : null)
    .catch((e) => [e]);
};

const cancelPlaceOrder = ({ orderNo }) => {
  const { CANCEL_PLACE_ORDER } = RestEndPoints;
  const orderCongif = [{ orderNo: orderNo }];
  const parameters = getRequestPayload(orderCongif);
  return wrappedFetch(CANCEL_PLACE_ORDER, parameters)
    .then((response) => response.json());
};

export {
  isValidAccessToken,
  placeOrder,
  fetchOrderBook,
  fetchTradeBook,
  fetchPositionBook,
  fetchHoldingBook,
  cancelPlaceOrder,
  modifyPlaceOrder,
};
