import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import Instrument from 'habitual-analytics/models/instrument/index';
import _ from 'lodash';

export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const instrumentDetails = _.find(
    tradingSymbolsByExchangeTokens,
    (value, key) => key == orderDetail?.scripToken
  );
  if (instrumentDetails === undefined) {
    return null;
  }

  if (orderDetail['exchangeName'] === 'NSE') {
    return new Instrument({
      symbol: instrumentDetails.tradingsymbol,
    });
  } else {

    return new Instrument({
      symbol: instrumentDetails.symbol,
      expiryDate: instrumentDetails.expiryDate,
      strikePrice: instrumentDetails.strikePrice,
      optionType: instrumentDetails.type,
    });
  }
};
