import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls';
import moment from 'moment';
import { getMarketTimingConfigs } from 'habitual-analytics/dateUtils/index';
import {
  DATE_FORMAT,
  EXPIRY_DATE_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import { DISPLAY_TRADING_SYMBOL_TO_OBJECT_REGEX } from 'habitual-analytics/models/instrument/factory';
import _ from 'lodash';
import getHistoricFormattedData from './formatter';

const HistoricDataAdaptor = (() => {
  const getParsedParameters = (datetimes, instruments) => {
    return {
      method: 'POST',
      body: JSON.stringify({
        instruments: _.join(instruments, ','),
        datetimes: `${datetimes}`,
        candleInterval: '1m',
      }),
    };
  };

  const fetchData = (parameters) => {
    const { TICKS_POINT_IN_TIME } = REST_URLS();

    return wrappedFetch(TICKS_POINT_IN_TIME, parameters).then((response) =>
      response.json().then(({ data }) => data)
    );
  };

  const getApiFormatInstrument = (instrument) => {
    const matches = instrument.match(DISPLAY_TRADING_SYMBOL_TO_OBJECT_REGEX);
    const [, symbol, expiryDate, strikePrice, optionType] = matches || [];
    const formattedExpiryDate = moment(expiryDate, EXPIRY_DATE_FORMAT).format(
      DATE_FORMAT
    );

    if (optionType === 'FUT') return `FUT|${symbol}|I`;
    else if (strikePrice)
      return `OPT|${symbol}|${formattedExpiryDate}|${strikePrice}|${optionType}`;
    else return `EQ|${instrument}`;
  };

  return {
    getData: async (instruments) => {
      const formattedInstruments = _.chain(instruments)
        .split(',')
        .map((instrument) => getApiFormatInstrument(instrument))
        .value();
      const {
        marketStartTime,
        firstOiStartTime,
        marketCurrentTime,
        marketPrevDayEndTime,
      } = getMarketTimingConfigs();

      return fetchData(
        getParsedParameters(
          [
            marketStartTime,
            firstOiStartTime,
            marketCurrentTime,
            marketPrevDayEndTime,
          ],
          formattedInstruments
        )
      ).then((responseData) => {
        return getHistoricFormattedData(responseData, {
          marketStartTime,
          firstOiStartTime,
          marketCurrentTime,
          marketPrevDayEndTime,
        });
      });
    },
  };
})();

export default HistoricDataAdaptor;
