import {
  segmentConfigs,
  nomenClatures,
  predictionTypes,
  trendingOIComparisonTypes,
  userRunTypes,
  placeOrderModes,
} from 'habitual-analytics/constants/habitual-configs';
import moment from 'moment';
import { defaultLegConfig } from 'habitual-analytics/utils/strategyConfigs';
import { defaultSettingPreferences } from 'habitual-analytics/constants/dynamicAppConfigs';
import { getMarketTimingConfigs } from 'habitual-analytics/dateUtils/index';
import {
  getUpComingExpiryDates,
} from 'habitual-analytics/dateUtils/expiryDateCalculations';
import { getAnalyticsUrlParams } from 'globals/utils/url';
import { instruments } from 'zeequant-constants';

const { setting_preferences: sp } = _.get(
  window,
  'instaConstants.userDetails',
  {}
);

const { marketCurrentTime, marketStartTime } = getMarketTimingConfigs();
const timeDifference = moment(marketCurrentTime).diff(
  moment(marketStartTime),
  'minutes'
);

const initialBasketConfigs = {
  legs: [defaultLegConfig],
  currentStrategy: 'shortStrangle',
};

const { expiryDate, instrument } = getAnalyticsUrlParams();
const defaultInstrument = instrument || 'BANKNIFTY';
const isIndex = _.includes(instruments?.indexSymbols, instrument);

const initialHeaderConfiguration = {
  instrument: defaultInstrument,
  segment: segmentConfigs.options.value,
  expiryDate:
    expiryDate ?? getUpComingExpiryDates(isIndex ? 'M' : 'W', defaultInstrument)[0],
  strikePrices: [],
  strikePrice: '',
  trendingOiStrikePrices: [],
  currentDate: null,
  timeInterval: timeDifference > 5 ? 3 : 1,
  comparisonInterval: 0,
  trendingOIComparisonType: trendingOIComparisonTypes.previousDay.value,
};

const findStrategyConfig = {
  type: nomenClatures.positive.value,
  predictionBegin: 1,
  predictionEnd: 1,
  predictionType: predictionTypes.percentage.value,
};

const initialCommonConfigs = {
  combinedPremiumData: null,
  basketOpen: false,
  isBasketSlideOpen: false,
  marketActiveTab: 'ALL',
  OiSpurtActiveTab: 'longBuildUp',
  underlyingPrice: '',
  isNavigateToBasket: false,
  settingPreferenceChangeFromType:
    sp?.changeFromType ?? defaultSettingPreferences?.changeFromType,
  isCombinedPremiumChartActive: false,
  isNavigateToPayoutChart: false,
  isNavigateToBasketOrder: false,
  isNavigateToOptionChain: false,
  currentBrokerName: window?.instaConstants?.userDetails?.broker,
  isModifyOrder: false,
  noDataMessage: 'New Data will be updated at 9.18am',
  userRunType: userRunTypes.live,
  historicalDateAndTime: null,
  indexContributorActiveInstrument: 'BANKNIFTY',
  historicUniqId: _.uniqueId('id-'),
  placeOrderMode: placeOrderModes.live,
};

const initialOrderPlacerConfig = {
  isOrderPlacerActive: false,
  orderPlacerData: null,
  handleAfterOrderPlacer: null,
  shouldMinimizeModal: false,
  basketName: null,
  basketId: null,
};

const initialState = {
  basketConfigs: {
    ...initialBasketConfigs,
  },
  headerConfigs: {
    ...initialHeaderConfiguration,
  },
  commonConfigs: {
    ...initialCommonConfigs,
  },
  findStrategyConfig: {
    ...findStrategyConfig,
  },
  orderPlacerConfig: {
    ...initialOrderPlacerConfig,
  },
};

export default initialState;
