import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import Instrument from 'habitual-analytics/models/instrument/index';
import { getFormattedTradingSymbolObject } from '../tradingSymbolParser';
import _ from 'lodash';

export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const fetchedTradingSymbol = _.find(
    tradingSymbolsByExchangeTokens,
    (value, key) => key == orderDetail?.ScripCode
  );
  if (fetchedTradingSymbol === undefined) {
    return null;
  }

  if (orderDetail?.ExchType === 'C') {
    const symbol = _.get(orderDetail, 'Symbol', '');
    return new Instrument({
      symbol: symbol ? symbol : fetchedTradingSymbol,
    });
  } else {
    return getFormattedTradingSymbolObject(fetchedTradingSymbol);
  }
};
