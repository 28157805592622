import moment from 'moment';
import _ from 'lodash';
import {
  getFixedData,
  getRoundedData,
} from 'habitual-analytics/common/formatter/number';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { arrayOfInstruments } from 'habitual-analytics/constants/instruments';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isNse,
  isOrderPartiallyExecuted,
} from 'habitual-analytics/brokers/utils';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import { 
  getEligibleProductCodeAndTypeList 
} from 'habitual-analytics/formProvider/OrderPlacerFormProvider/processor';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
  getPlaceOrderTradingSymbol,
} from '../tradingSymbolParser';

const sanitizeAndParseOrderStatus = (orderDetail) => {
  const orderStatus = _.get(orderDetail, 'status', '');
  let status;
  switch (orderStatus) {
    case 1:
      status = statusConfigs?.cancelled?.value;
      break;
    case 2:
      status = statusConfigs?.executed?.value;
      break;
    case 5:
      status = statusConfigs?.failed?.value;
      break;
    case 6:
      status = statusConfigs?.pending?.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.filledQty
    ):
      status = statusConfigs?.partiallyExecuted?.value;
      break;
    default:
      status = statusConfigs?.placed?.label;
      break;
  }

  return status;
};

const parseOrderType = (orderType) => {
  let type;
  switch (orderType) {
    case 1:
      type = 'LIMIT';
      break;
    case 2:
      type = 'MARKET';
      break;
    case 3:
      type = 'STOP';
      break;
    case 4:
      type = 'STOPLIMIT';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pcode) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
      productCode = 'CNC';
      break;
    case 'mis':
      productCode = 'INTRADAY';
      break;
    case 'nrml':
      productCode = 'MARGIN';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const parseOrderTypeNumber = (orderType) => {
  let type;
  switch (_.trim(orderType)) {
    case 'l':
      type = 1;
      break;
    case 'mkt':
      type = 2;
      break;
    case 'sl-m':
      type = 3;
      break;
    case 'sl':
      type = 4;
      break;
    default:
      type = '';
  }
  return type;
};

const parseOrderBook = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'symbol', '').split(/[:]+/);
  const exchange = _.get(tradingSymbol, '0', '');
  const instrument = _.get(tradingSymbol, '1', '');

  if (!isNse(exchange, instrument)) {
    return null;
  }

  const tradedTime = _.get(orderDetail, 'orderDateTime', '').split(' ')[1];
  const tradedQuantity = _.get(orderDetail, 'filledQty', 0);
  const Quantity = _.get(orderDetail, 'qty', 0);
  const productCode = _.get(orderDetail, 'productType', '');
  const productType = parseOrderType(_.get(orderDetail, 'type', ''));
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);

  const tradingSymbolObj = getFormattedTradingSymbolObject(instrument);
  const failedReason = _.get(orderDetail, 'message', '');

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    type:
      _.get(orderDetail, 'side', '') === 1
        ? transactionTypes.buy.value
        : transactionTypes.sell.value,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    status,
    isCancellableOrder,
    stopPrice: _.get(orderDetail, 'stopPrice', ''),
    limitPrice: _.get(orderDetail, 'limitPrice', ''),
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${Quantity}`,
      orderNo: _.get(orderDetail, 'id', ''),
      tradedPrice: _.get(orderDetail, 'tradedPrice', ''),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'symbol', '').split(/[:]+/);
  const exchange = _.get(tradingSymbol, '0', '');
  const instrument = _.get(tradingSymbol, '1', '');

  if (!isNse(exchange, instrument)) {
    return null;
  }

  const tradedTime = _.get(orderDetail, 'orderDateTime', '').split(' ')[1];
  const productType = parseOrderType(_.get(orderDetail, 'orderType', ''));
  const productCode = _.get(orderDetail, 'productType', '');
  const tradedQuantity = Number(_.get(orderDetail, 'tradedQty', 0));
  const quantity = Number(_.get(orderDetail, 'tradedQty', 0));
  const status = statusConfigs.executed.label;
  const tradingSymbolObj = getFormattedTradingSymbolObject(instrument);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'side', '') === 1
        ? transactionTypes.buy.value
        : transactionTypes.sell.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'tradePrice', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'symbol', '').split(/[:]+/);
  const exchange = _.get(tradingSymbol, '0', '');
  const instrument = _.get(tradingSymbol, '1', '');

  if (!isNse(exchange, instrument)) {
    return null;
  }

  const tradingSymbolObj = getFormattedTradingSymbolObject(instrument);

  const ltp = _.get(orderDetail, 'ltp', 0);
  const qty = _.get(orderDetail, 'netQty', 0);
  const buyAvg = getFixedData(_.get(orderDetail, 'buyAvg', 0));
  const sellAvg = getFixedData(_.get(orderDetail, 'sellAvg', 0));
  const type =
    _.get(orderDetail, 'side', '') === 1
      ? transactionTypes.buy.value
      : transactionTypes.sell.value;
  const orderValue = type === transactionTypes.buy.value ? buyAvg : sellAvg;
  const profitAndLoss = _.round(_.get(orderDetail, 'pl', 0), 2);
  const realisedprofitloss = _.round(
    _.get(orderDetail, 'realized_profit', 0),
    2
  );
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - orderValue : orderValue - ltp;
  const profitLoss =
    qty === 0
      ? profitAndLoss
      : _.round(currentProfitLoss, 2) * Math.abs(qty) + realisedprofitloss;

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    ...orderDetail,
    tradingSymbolObj,
    type:
      _.get(orderDetail, 'side', '') === 1
        ? transactionTypes.buy.value
        : transactionTypes.sell.value,
    qty,
    buyAvg,
    sellAvg,
    ltp: getRoundedData(ltp),
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'productType', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'productType', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type,
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'symbol', '').split(/[:]+/);
  const exchange = _.get(tradingSymbol, '0', '');
  const instrument = _.get(tradingSymbol, '1', '');

  if (!isNse(exchange, instrument)) {
    return null;
  }

  const qty = _.get(orderDetail, 'quantity', 0);
  const ltp = _.get(orderDetail, 'ltp', 0);
  const costPrice = _.get(orderDetail, 'costPrice', 0);
  const profitandloss = (ltp - costPrice) * qty;

  const netChg = getRoundedData((profitandloss / costPrice) * 100);
  const tradingSymbolObj = getFormattedTradingSymbolObject(instrument);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss: profitandloss,
    extraDetails: {
      quantity: _.get(orderDetail, 'quantity', 0),
      buyAverage: costPrice,
      buyValue: costPrice,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfigs) => {
  const formattedOrderConfigs = await Promise.all(
    _.map(orderConfigs, (orderConfig) => {
      const { tradingSymbolObj } = orderConfig;
      const formattedTradingSymbol =
        getPlaceOrderTradingSymbol(tradingSymbolObj);
      const exchange = tradingSymbolObj?.getExchange();

      return {
        symbol: `${exchange}:${formattedTradingSymbol}`,
        qty: _.parseInt(_.get(orderConfig, 'qty', '')),
        type: parseOrderTypeNumber(_.toLower(_.get(orderConfig, 'prctyp', ''))),
        side:
          _.toLower(_.get(orderConfig, 'transactionType', '')) === 'buy'
            ? 1
            : -1,
        productType: parseProductCode(_.get(orderConfig, 'pCode', '')),
        limitPrice: parseFloat(_.get(orderConfig, 'price', '')),
        stopPrice: parseFloat(_.get(orderConfig, 'trigPrice', '')),
        disclosedQty: _.parseInt(_.get(orderConfig, 'disCloseQty', '')),
        validity: _.get(orderConfig, 'ret', ''),
        offlineOrder: false,
        stopLoss: 0,
        takeProfit: 0,
      };
    })
  );
  return formattedOrderConfigs;
};

export const parseMarginCalculator = async (orderConfigs) => {
  const formattedMarginCalculatorConfigs = await Promise.all(
    _.map(orderConfigs, (orderConfig) => {
      const { tradingSymbolObj } = orderConfig;
      const { eligibleProductCodeList } =
        getEligibleProductCodeAndTypeList(tradingSymbolObj);
      const formattedTradingSymbol =
        getPlaceOrderTradingSymbol(tradingSymbolObj);
      const pCode = parseProductCode(_.get(orderConfig, 'pCode', ''));
      const productType = _.toLower(_.get(orderConfig, 'prctyp', ''));

      const selectedProductCode = _.find(
        eligibleProductCodeList,
        (productTypeList) => productTypeList.name === pCode
      );

      const exchange = tradingSymbolObj?.getExchange();

      return {
        symbol: `${exchange}:${formattedTradingSymbol}`,
        qty: _.parseInt(_.get(orderConfig, 'qty', '')),
        type: parseOrderTypeNumber(productType),
        side:
          _.toLower(_.get(orderConfig, 'transactionType', '')) === 'buy'
            ? 1
            : -1,
        productType: selectedProductCode?.name ? pCode : 'INTRADAY',
        limitPrice: !_.includes(['mkt', 'sl-m'], productType)
          ? Number(_.get(orderConfig, 'price', ''))
          : 0,
        stopLoss: 0,
        stopPrice: !_.includes(['l', 'mkt'], productType)
          ? Number(_.get(orderConfig, 'trigPrice', ''))
          : 0,
      };
    })
  );
  return formattedMarginCalculatorConfigs;
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

const parsePostbackOrder = (orderDetail) => {
  const tradingSymbol = _.get(orderDetail, 'symbol', '').split(/[:]+/);
  const exchange = _.get(tradingSymbol, '0', '');
  const instrument = _.get(tradingSymbol, '1', '');

  if (!isNse(exchange, instrument)) {
    return null;
  }

  const status = sanitizeAndParseOrderStatus(orderDetail);
  const tradingSymbolObj = getFormattedTradingSymbolObject(instrument);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  const failedReason = _.get(orderDetail, 'omsMessage', '') || _.get(orderDetail, 'message', '');

  return {
    tradingSymbolObj,
    status,
    failedReason,
    extraDetails: {
      orderNo: _.get(orderDetail, 'id', ''),
    },
  };
};

export {
  parseOrderBook,
  parseTradeBook,
  parsePositionBook,
  parseHoldingsBook,
  parseOrderDetails,
  parsePlaceOrder,
  parsePostbackOrder,
};
