import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import Instrument from 'habitual-analytics/models/instrument/index';
import _ from 'lodash';

const brokerSpecificExchangeId = (type) => {
  let exchangeId;
  switch (type) {
    case 'order':
      return (exchangeId = 'ExchangeInstrumentID');
    case 'trade':
      return (exchangeId = 'ExchangeInstrumentID');
    case 'position':
      return (exchangeId = 'ExchangeInstrumentId');
    case 'holdings':
      return (exchangeId = 'ExchangeNSEInstrumentId');
    default:
      exchangeId = 'ExchangeInstrumentID';
  }
  return exchangeId;
};

export const parseOrderDetailToGetTradingSymbolObj = (orderDetail, type = 'order') => {
  const exchangeId = brokerSpecificExchangeId(type);
  const instrumentDetailsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens',
    {}
  );
  const instrumentDetails = _.find(
    instrumentDetailsByExchangeTokens,
    (value, key) => key == orderDetail[exchangeId]
  );
  if (instrumentDetails?.exchange === 'NSE') {
    const symbol = _.get(instrumentDetails, 'tradingsymbol', '');
    return new Instrument({
      symbol: symbol ? symbol : instrumentDetails,
    });
  } else {
    const symbol = _.get(instrumentDetails, 'symbol', '');
    const date = _.get(instrumentDetails, 'expiryDate', '');
    const strike = _.get(instrumentDetails, 'strikePrice', '');
    const optionType = _.get(instrumentDetails, 'type', '');
    return new Instrument({
      symbol: symbol,
      expiryDate: date,
      strikePrice: Number(strike) && strike,
      optionType: optionType === 'FUT' ? undefined : optionType,
    });
  }
};
