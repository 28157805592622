import { getCurrentDate } from 'habitual-analytics/utils/datetime';

class TickerObserver {
  constructor(onHalt, onInitiate) {
    this.onHalt = onHalt;
    this.onInitiate = onInitiate;
    this.isHaltingHappend = false;
  }

  update = (intervalId) => {
    const now = getCurrentDate();
    const haltTime = getCurrentDate()
      .set('year', now.year()).set('month', now.month()).set('date', now.date())
      .set('hour', 9)
      .set('minute', 16)
      .set('second', 0);

    const initiateTime = getCurrentDate()
      .set('year', now.year()).set('month', now.month()).set('date', now.date())
      .set('hour', 9)
      .set('minute', 16)
      .set('second', 5);

    // Get hours, minutes, and seconds from each date
    const nowHours = now.hour();
    const nowMinutes = now.minute();
    const nowSeconds = now.second();

    const haltTimeHours = haltTime.hour();
    const haltTimeMinutes = haltTime.minute();
    const haltTimeSeconds = haltTime.second();

    const initiateTimeHours = initiateTime.hour();
    const initiateTimeMinutes = initiateTime.minute();
    const initiateTimeSeconds = initiateTime.second();

    // Check if hours, minutes, and seconds match
    const haltTimeMatched = nowHours === haltTimeHours
      && nowMinutes === haltTimeMinutes && nowSeconds === haltTimeSeconds;
    const initiateTimeMatched = nowHours === initiateTimeHours
      && nowMinutes === initiateTimeMinutes && nowSeconds === initiateTimeSeconds;

    if (haltTimeMatched) {
      this.isHaltingHappend = true;
      this.onHalt();
    } else if (initiateTimeMatched && this.isHaltingHappend) {
      this.onInitiate();
    } else if (now > initiateTime) {
      console.log('TickerObserver: Stopping ticker observer');

      clearInterval(intervalId);
    }
  };
}

export default TickerObserver;
