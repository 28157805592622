import { getFormattedMoney } from 'habitual-analytics/utils/money';
import _ from 'lodash';
import { InstrumentDataAdaptor } from 'habitual-analytics/api/services/adaptor';

export const getBasketFormOrderDetails = async (legs) => {
  const formattedTradingSymbols = _.map(legs, (leg) =>
    leg?.tradingSymbolObj?.toString()
  );

  const instrumenstLiveData = await InstrumentDataAdaptor.getData(
    _.join(formattedTradingSymbols, ',')
  );

  return _.map(legs, (leg) => {
    const result = _.get(instrumenstLiveData, 'marketData', {});
    const ltp = _.get(result[leg.tradingSymbolObj.toString()], 'ltp', 0);
    return {
      ...leg,
      price: ltp,
      ltpDisplayFormat: getFormattedMoney(ltp, false, false, 'INR', false),
    };
  });
};
