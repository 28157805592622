import React, { lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import useUserModeColors from 'globals/hooks/useUserModeColors';
import withResponsive from 'globals/components/withResponsive';
import { OriginUtility } from '../../globals/utils';

const MobileAppLayout = lazy(() => import('./mobile'));
const WebAppLayout = lazy(() => import('./web'));

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {};

const ResponsiveComponent = withResponsive(MobileAppLayout, WebAppLayout);

const AppLayout = (props) => {
  useUserModeColors();

  useEffect(() => {
    const origin = OriginUtility.getOrigin();
    if(!origin) return;

    const decoded = decodeURIComponent(origin);
    if(decoded === window.location.href) return;
    if(decoded === '/') return;

    window.location.href = decoded;
  }, []);

  return (
    <ResponsiveComponent {...props} />
  );
};

AppLayout.propTypes = propTypes;
AppLayout.defaultProps = defaultProps;

export default AppLayout;
