import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';

import ALICEBLUE from './aliceblue/index';
import BROKER_STUB from './broker_stub/index';
import ANGEL_BROKING from './angel_broking/index';
import GOOD_WILL from './good_will/index';
import FYERS from './fyers/index';
import NUVAMA from './nuvama/index';
import ZEBU from './zebu/index';
import MAITRA_COMMODITIES from './maitraCommodities/index';
import TRADESMART from './tradesmart/index';
import ENRICH_MONEY from './enrich_money/index';
import HDFCSKY from './hdfcsky/index';
import PRABHUDAS from './prabhudas/index';
import RELIGARE from './religare/index';
import UPSTOX from './upstox/index';
import PAYTM_MONEY from './paytm_money/index';
import PHILIP_CAPITAL from './phillip_capital/index';
import FINVASIA from './finvasia/index';
import PROFITMART from './profitmart/index';
import INVESTRIGHT from './investright/index';
import PAPER_TRADE from './paper_trade/index';
import FIVEPAISA from './fivepaisa/index';
import AXIS_DIRECT from './axis_direct/index';
import SMC_GLOBAL from './smc_global/index';
import STOX_KART from './stox_kart/index';
import DHANI_STOCKS from './dhani_stocks/index';
import IIFL_NOVA from './iifl_nova/index';
import ZEBU_XTS from './zebu_xts/index';
import SKY_BROKING from './sky_broking/index';

const AVAILABLE_BROKERS = {
  ALICEBLUE: ALICEBLUE,
  BROKER_STUB: BROKER_STUB,
  ANGEL_BROKING: ANGEL_BROKING,
  GOODWILL: GOOD_WILL,
  FYERS: FYERS,
  NUVAMA: NUVAMA,
  AXIS_DIRECT: AXIS_DIRECT,
  ZEBU: ZEBU,
  TRADESMART: TRADESMART,
  MAITRA_COMMODITIES: MAITRA_COMMODITIES,
  ENRICH_MONEY: ENRICH_MONEY,
  HDFCSKY: HDFCSKY,
  PRABHUDAS: PRABHUDAS,
  RELIGARE: RELIGARE,
  UPSTOX: UPSTOX,
  PAYTM_MONEY: PAYTM_MONEY,
  PHILIP_CAPITAL: PHILIP_CAPITAL,
  FINVASIA: FINVASIA,
  PROFITMART: PROFITMART,
  INVESTRIGHT: INVESTRIGHT,
  PAPER_TRADE: PAPER_TRADE,
  FIVEPAISA: FIVEPAISA,
  SMC_GLOBAL: SMC_GLOBAL,
  STOX_KART: STOX_KART,
  DHANI_STOCKS: DHANI_STOCKS,
  IIFL_NOVA: IIFL_NOVA,
  ZEBU_XTS: ZEBU_XTS,
  SKY_BROKING: SKY_BROKING,
};

export const fetchApiFunc = async (funcName, args = []) => {
  const { broker } = getDynamicAppConfigs().brokerConfigs;
  const isPaperTrade = getDynamicAppConfigs().isPaperTrade;

  const uptadeBroker = isPaperTrade
    ? funcName === 'fetchMarginCalculator'
      ? broker
      : 'paper_trade'
    : broker;

  return AVAILABLE_BROKERS[_.toUpper(uptadeBroker)][funcName]?.(args);
};

export const fetchBrokerConfigs = (funcName, ...args) => {
  const { broker } = getDynamicAppConfigs().brokerConfigs;

  return AVAILABLE_BROKERS[_.toUpper(broker)][funcName]?.(...args);
};
