import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { toast } from 'react-toastify';

export const TOASTIFY_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  DEFAULT: 'default',
};

export const toastifyPositions = {
  topRight: 'top-right',
  topLeft: 'top-left',
  bottomRight: 'bottom-right',
  bottomLeft: 'bottom-left',
  topCenter: 'top-center',
};

export const reactToastify = (
  type = TOASTIFY_TYPES.INFO,
  message,
  isOrderExecution = false
) => {
  const userTheme = _.get(window, 'instaConstants.userDetails.theme', 'light');

  const { notificationSound } =
    getDynamicAppConfigs()?.settingPreferences ?? {};

  const renderToast = () =>
    toast(message ?? 'Sorry something went wrong!', {
      position: toastifyPositions.topCenter,
      autoClose: 3000,
      hideProgressBar: false,
      type,
      theme: userTheme,
    });

  if (notificationSound === 'yes' && isOrderExecution) {
    try {
      const audio = new Audio('./mp3/toast_sound.mp3');
      audio.play().then(() => renderToast());
    } catch (error) {
      // If audio is not supported or blocked by browser
      console.error(error);
      renderToast();
    }
  } else renderToast();
};
