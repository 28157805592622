import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { userRunTypes } from 'habitual-analytics/constants/habitual-configs';
import { getCurrentLocalizeDate, getParsedDateObj } from './timezone';
import {
  CHART_TIME_FORMAT,
  DATE_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import moment from 'moment';
import { getValidMarketDate } from 'habitual-analytics/dateUtils/index';

export const getCurrentDate = () => {
  const { 
    userRunType, 
    historicalDateAndTime 
  } = getDynamicAppConfigs()?.historicalConfigs || {};

  const currentDate =
    userRunType === userRunTypes.historical
      ? getParsedDateObj(historicalDateAndTime)
      : getCurrentLocalizeDate();

  return currentDate;
};

export const checkCurrentTimeIsValid = (date = '', time = '') => {
  const [hour, minute] = time?.split(':') || [];
  const [year, month, dateNo] = date?.split('-') || [];

  const selectedDateAndTime = getCurrentLocalizeDate()
    .set('date', dateNo)
    .set('month', month - 1)
    .set('year', year)
    .set('hour', hour)
    .set('minute', minute)
    .set('second', 0);

  const currentDateAndTime = getCurrentLocalizeDate();

  const marketStartTime = moment('09:17', CHART_TIME_FORMAT);
  const marketEndTime = moment('15:29', CHART_TIME_FORMAT);
  const selectedTime = moment(time, CHART_TIME_FORMAT);

  if (selectedDateAndTime.isSame(currentDateAndTime, 'day')) {
    return (
      selectedDateAndTime.isBefore(currentDateAndTime, 'minute') &&
      selectedTime.isBetween(marketStartTime, marketEndTime, null, '[]')
    );
  }

  return selectedTime.isBetween(marketStartTime, marketEndTime, null, '[]');
};

export const isCurrentTimeWithinTradingHours = () => {
  const currentDateAndTime = getCurrentLocalizeDate();
  const marketStartTime = moment('09:17', CHART_TIME_FORMAT);
  const marketEndTime = moment('15:29', CHART_TIME_FORMAT);

  const validCurrentDate = getValidMarketDate(
    currentDateAndTime.clone().format(DATE_FORMAT)
  );
  const isCurrentDayWorkingDay =
    validCurrentDate === currentDateAndTime.format(DATE_FORMAT);

  return (
    currentDateAndTime.isBetween(marketStartTime, marketEndTime, null, '[]') &&
    isCurrentDayWorkingDay
  );
};
