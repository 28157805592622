import _ from 'lodash';
import EventEmitter from 'events';
import { TICKER_EVENTS } from '../constants/habitual-configs';

class GenericTicker extends EventEmitter {
  constructor() {
    super();

    this.clients = {};
    this.state = {};
    this.additionalData = {};
    this.instruments = [];
    this.userDetails = null;
    this.socket = null;
    this.debugLog = _.noop;
    this.socketConnectPromise = null;

    // Bind event handler to this context
    this.handleVisibilityAndFocusChange =
      this.handleVisibilityAndFocusChange.bind(this);
  }

  // Start method: Adds the unified event listener
  async start(userDetails, brokerTicker) {
    this.addEventListeners();

    if (this.socket) {
      this.debugLog('Socket already connected');
      return;
    }

    if (!userDetails) {
      this.debugLog('Invalid user details');
      return;
    }

    this.userDetails = userDetails;
    this.brokerTicker = brokerTicker;

    await this.brokerTicker.init(this);
    this.socketConnectPromise = this.brokerTicker.start();
    return this.socketConnectPromise;
  }

  // Unified event handler for visibility and focus changes
  handleVisibilityAndFocusChange() {
    if (document.hidden) {
      this.debugLog('Window is hidden or unfocused, halting ticker updates...');
      this.halt();
    } else {
      this.debugLog(
        'Window is visible and focused, resuming ticker updates...'
      );
      this.initiate();
    }
  }

  // Method to add event listeners for visibility, blur, and focus
  addEventListeners() {
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityAndFocusChange
    );
  }

  // Method to remove event listeners
  removeEventListeners() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityAndFocusChange
    );
  }

  async onSocketConnect(socket) {
    this.socket = socket;
  }

  async subscribe(clientId, instruments) {
    const existingClient = this.clients[clientId];
    if (existingClient && _.some(existingClient.instruments, instruments)) {
      this.debugLog('[ALT] Already subscribed. Duplicate request.');
      return;
    }

    this.clients[clientId] = {
      clientId,
      instruments: _.chain(instruments)
        .thru((result = []) => [
          ...result,
          ..._.get(this.clientId, `${clientId}.instruments`, []),
        ])
        .uniq()
        .value(),
    };

    if (!this.socket) {
      this.debugLog('[ALT] Socket not connected or disconnected.');
      return;
    }

    const newTradingSymbols = _.difference(instruments, this.instruments);
    if (_.isEmpty(newTradingSymbols)) {
      this.debugLog('[LT] Trading symbol already subscribed.');
      return Promise.resolve([this.state]);
    }

    this.instruments = this.instruments.concat(newTradingSymbols);

    return this.brokerTicker.subscribe(newTradingSymbols);
  }

  initiate = async () => {
    return this.brokerTicker.subscribe(this.instruments);
  };

  halt = async () => {
    return this.brokerTicker.unsubscribe(this.instruments);
  };

  unsubscribeUnusedTradingSymbols = (clientId) => {
    if (!this.clients[clientId]) {
      this.debugLog('[ALT] Already unsubscribed. Duplicate request.');
      return;
    }

    this.debugLog('[ALT] Unsubscribing...');
    delete this.clients[clientId];

    if (!this.socket) {
      this.debugLog('[ALT] Unsubscribe socket not connected or disconnected.');
      return;
    }

    const newTradingSymbols = _.chain(this.clients)
      .flatMap(({ instruments }) => instruments)
      .uniq()
      .value();

    const staleTradingSymbols = _.difference(
      this.instruments,
      newTradingSymbols
    );
    this.debugLog('[ALT] Stale instruments:', staleTradingSymbols);
    this.instruments = this.instruments.filter(
      (instrument) => !staleTradingSymbols.includes(instrument)
    );

    if (_.isEmpty(staleTradingSymbols)) {
      this.debugLog('[ALT] Other clients are still subscribed.');
      return;
    }

    this.brokerTicker.unsubscribe(staleTradingSymbols);
  };

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  unsubscribe(clientId) {
    return this.delay(2000).then(() =>
      this.unsubscribeUnusedTradingSymbols(clientId)
    );
  }

  // Destroy method: Removes event listeners and destroys broker ticker
  silentDestroy() {
    this.removeEventListeners();

    if (this?.brokerTicker?.destroy) {
      this.brokerTicker.destroy();
      this.debugLog('Socket disconnected');
      this.socket = null;
    }
  }

  onState(emitTicks) {
    this.emit(TICKER_EVENTS.TICKS, emitTicks);
  }

  onTick(emitTicks) {
    this.emit(TICKER_EVENTS.TICKS, emitTicks);
  }
}

export const genericTicker = new GenericTicker();
