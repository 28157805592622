import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls';

const LiveDataAdaptor = (() => {
  return {
    getData: async (instruments) => {
      const { GET_LIVE_DATA } = REST_URLS();
      const parameters = {
        method: 'POST',
        body: JSON.stringify({ instruments: instruments }),
      };
      const uri = `${GET_LIVE_DATA}`;

      return wrappedFetch(uri, parameters)
        .then((response) => response.json())
        .then((result) => {
          const newMarketData = {};

          for (const key in result.marketData) {
            const item = result.marketData[key] || {};
            const newAdditionalData = result?.additionalData?.[key] || {};

            newMarketData[key] = {
              ...item,
              firstOpen: _.get(newAdditionalData, 'firstOpen', 0),
              prevDayLtp: _.get(newAdditionalData, 'prevDayLtp', 0),
              firstOi: _.get(newAdditionalData, 'firstOi', 0),
              prevDayOi: _.get(newAdditionalData, 'prevDayOi', 0),
            };
          };

          return {
            marketData: newMarketData,
          };
        });
    },
  };
})();

export default LiveDataAdaptor;
