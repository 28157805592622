import { getMarketTimingConfigs } from 'habitual-analytics/dateUtils/index';
import { REST_URLS } from '../resturls/index';
import qs from 'qs';
import { wrappedFetch } from './base/index';
import _ from 'lodash';

export const getStrikesPricesBasedOnInstrument = _.memoize((args = {}) => {
  const { STRIKE_PRICE } = REST_URLS();
  const { instrument = '', expiryDate = '' } = args;
  const { marketCurrentTime } = getMarketTimingConfigs();

  const queryParams = qs.stringify({
    instrument: `${instrument}`,
    expiry_date: `${expiryDate}`,
    date_time: marketCurrentTime,
  });

  const uri = `${STRIKE_PRICE}?${queryParams}`;
  return wrappedFetch(uri)
    .then((response) => response.json())
    .then(({ data }) => {
      return _.chain(data)
        .map((sp) => `${parseFloat(sp)}`)
        .sortBy((sp) => parseFloat(sp))
        .uniq()
        .value();
    }).catch((error) => {
      console.log('Error in fetching strike prices', error);
      return [];
    });
}, ({ instrument, expiryDate }) => `${instrument}-${expiryDate}`);