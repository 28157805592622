import moment from 'moment';
import _ from 'lodash';
import {
  getFixedData,
  getRoundedData,
} from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from '../../dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from '../../constants/habitual-configs';
import { getFormattedMoney } from '../../utils/money';
import { arrayOfInstruments } from 'habitual-analytics/constants/instruments';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  isNse,
} from '../utils';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
  getPlaceOrderTradingSymbol,
} from '../tradingSymbolParser/index';
import { isinCode } from 'zeequant-constants';

const parseProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'l':
      type = 'LMT';
      break;
    case 'mkt':
      type = 'MKT';
      break;
    case 'sl-m':
      type = 'SL-MKT';
      break;
    case 'sl':
      type = 'SL-LMT';
      break;
    default:
      type = '';
  }
  return type;
};

const defaultProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'lmt':
      type = 'L';
      break;
    case 'mkt':
      type = 'MKT';
      break;
    case 'sl-mkt':
      type = 'SL-M';
      break;
    case 'sl-lmt':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pcode) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
      productCode = 'C';
      break;
    case 'mis':
      productCode = 'I';
      break;
    case 'nrml':
      productCode = 'M';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.status)) {
    case 'canceled':
      status = statusConfigs.cancelled.value;
      break;
    case 'complete':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch', '');
  const tradingSymbol = _.get(orderDetail, 'tsym', '');

  if (!isNse(exchange, tradingSymbol)) {
    return null;
  }

  const productType = _.get(orderDetail, 'prctyp', '');
  const productCode = _.get(orderDetail, 's_prdt_ali', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trgprc', 0));
  const price = convertToNumber(_.get(orderDetail, 'prc', 0));
  const tradedQty = _.get(orderDetail, 'fillshares', 0);
  const quantity = Number(_.get(orderDetail, 'qty', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'avgprc', 0));
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice
        : price;
  const tradedTime = _.get(orderDetail, 'norentm', '').split(' ')?.[0];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'rejreason', '');
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);
  const formattedConfig = {
    tradingSymbolObj: tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'trantype', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'norenordno', ''),
      defaultProductType: defaultProductType(productType),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
    },
  };
  return formattedConfig;
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch', '');
  const tradingSymbol = _.get(orderDetail, 'tsym', '');

  if (!isNse(exchange, tradingSymbol)) {
    return null;
  }

  const productType = _.get(orderDetail, 'prctyp', '');
  const productCode = _.get(orderDetail, 's_prdt_ali', '');
  const tradedQuantity = Number(_.get(orderDetail, 'flqty', 0));
  const quantity = Number(_.get(orderDetail, 'qty', 0));
  const status = statusConfigs.executed.label;

  return {
    tradingSymbolObj: getFormattedTradingSymbolObject(tradingSymbol),
    time: moment(
      _.get(orderDetail, 'fltm', '')?.split(' ')?.[1],
      TIME_FORMAT
    ).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'trantype', '') === 'S'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'flprc', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch', '');
  const tradingSymbol = _.get(orderDetail, 'tsym', '');

  if (!isNse(exchange, tradingSymbol)) {
    return null;
  }

  const ltp = convertToNumber(_.get(orderDetail, 'lp'));
  const qty = Number(_.get(orderDetail, 'netqty', 0));
  const cfBuyAvg = Number(_.get(orderDetail, 'cfbuyavgprc', 0));
  const dayBuyAvg = Number(_.get(orderDetail, 'daybuyavgprc', 0));
  const cfSellAvg = Number(_.get(orderDetail, 'cfsellavgprc', 0));
  const daySellAvg = Number(_.get(orderDetail, 'daysellavgprc', 0));
  const buyAvg = cfBuyAvg ? cfBuyAvg : dayBuyAvg;
  const sellAvg = cfSellAvg ? cfSellAvg : daySellAvg;
  const realisedprofitloss = _.round(_.get(orderDetail, 'rpnl', 0), 2);
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - buyAvg : sellAvg - ltp;
  const profitLoss =
    qty === 0
      ? realisedprofitloss
      : _.round(currentProfitLoss, 2) * Math.abs(qty) + realisedprofitloss;
  const tradingSymbolObj = getFormattedTradingSymbolObject(tradingSymbol);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 's_prdt_ali', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'lp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 's_prdt_ali', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exch_tsym[0][exch]', '');
  const isin = _.get(orderDetail, 'exch_tsym[0][isin]', '');
  const tradingSymbol = _.findKey(isinCode, (code) => code === isin);

  if (!isNse(exchange, tradingSymbol)) {
    return null;
  }

  if (!tradingSymbol) {
    return null;
  }
  const tradingSymbolObj = getFormattedTradingSymbolObject(
    `${tradingSymbol}-EQ`
  );
  const quantity = _.get(orderDetail, 'holdqty', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'upldprc', 0));
  const firstOpen = _.get(orderDetail, 'firstOpen', 0);
  const ltp = convertToNumber(_.get(orderDetail, 'LTnse', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);
  const todayProfitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(firstOpen, ',', '')) * quantity
  );
  const todayNetChg = getRoundedData((todayProfitLoss / firstOpen) * 100);

  if (!_.includes(arrayOfInstruments(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      todayNetChg: `${getFormattedMoney(todayNetChg)}%`,
      todayProfitLoss,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'LTnse',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);
  const exchangeSegement =
    tradingSymbolObj?.isEquity() && tradingSymbolObj?.getExchange() === 'NSE'
      ? 'NSE'
      : tradingSymbolObj?.getExchange() === 'BSE'
        ? 'BFO'
        : 'NFO';

  return {
    uid: brokerClientId,
    actid: brokerClientId,
    exch: exchangeSegement,
    tsym: formattedTradingSymbol,
    qty: _.get(orderConfig, 'qty', '').toString(),
    prc: _.get(orderConfig, 'price', '').toString(),
    trgprc: _.get(orderConfig, 'trigPrice', '').toString(),
    prd: parseProductCode(_.get(orderConfig, 'pCode', '')),
    trantype: _.first(_.get(orderConfig, 'transactionType', '').toUpperCase()),
    prctyp: parseProductType(_.get(orderConfig, 'prctyp', '')),
    ret: _.get(orderConfig, 'ret', ''),
    remarks: 'INSTAOPTIONS',
  };
};

const parseModifyOrder = (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);
  const prodcutType = parseProductType(_.get(orderConfig, 'prctyp', ''));
  const exchangeSegement =
    tradingSymbolObj?.isEquity() && tradingSymbolObj?.getExchange() === 'NSE'
      ? 'NSE'
      : tradingSymbolObj?.getExchange() === 'BSE'
        ? 'BFO'
        : 'NFO';

  const parseOptions = {
    uid: brokerClientId,
    actid: brokerClientId,
    norenordno: _.get(orderConfig, 'orderNo', ''),
    exch: exchangeSegement,
    tsym: formattedTradingSymbol,
    qty: _.get(orderConfig, 'qty', '').toString(),
    prc: _.get(orderConfig, 'price', '').toString(),
    prctyp: prodcutType,
    ret: _.get(orderConfig, 'ret', ''),
    dscqty: _.get(orderConfig, 'disCloseQty', ''),
    remarks: 'INSTAOPTIONS',
  };

  if (_.includes(['SL-LMT', 'SL-MKT'], prodcutType)) {
    parseOptions.trgprc = getFixedData(
      _.get(orderConfig, 'trigPrice', '')
    ).toString();
  }

  return parseOptions;
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

const parseMarginCalculator = (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);
  const exchangeSegement =
    tradingSymbolObj?.isEquity() && tradingSymbolObj?.getExchange() === 'NSE'
      ? 'NSE'
      : tradingSymbolObj?.getExchange() === 'BSE'
        ? 'BFO'
        : 'NFO';

  return {
    uid: brokerClientId,
    actid: brokerClientId,
    exch: exchangeSegement,
    tsym: formattedTradingSymbol,
    qty: _.get(orderConfig, 'qty', '').toString(),
    prc: _.get(orderConfig, 'price', '').toString(),
    prd: parseProductCode(_.get(orderConfig, 'pCode', '')),
    trantype: _.first(_.get(orderConfig, 'transactionType', '').toUpperCase()),
    prctyp: parseProductType(_.get(orderConfig, 'prctyp', '')),
  };
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
  parseModifyOrder,
  parseMarginCalculator,
};
