import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import {
  parseMarginCalculator,
  parseCancelOrder,
  parseModifyOrder,
  parseOrderDetails,
  parsePlaceOrder,
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls';

const getHeaders = (mobileNumber) => {
  const { envs } = getDynamicAppConfigs();
  const { DHANI_STOCKS_APP_KEY, DHANI_STOCKS_APP_ID } = envs;
  let cookies = `ipaddress=192.168.1.1; app-id=${DHANI_STOCKS_APP_ID}; `;
  cookies += `app-key=${DHANI_STOCKS_APP_KEY}; mobileNumber=${mobileNumber}`;

  return {
    'custom-cookie': cookies,
  };
};

const getRequestUri = (method) => {
  const baseUrl = REST_URLS().BROKER_PROXY;
  return `${baseUrl}?broker=dhani_stocks&method=${method}`;
};

const isValidAccessToken = async () => {
  const otpSessionId = _.get(
    window,
    'instaConstants.userDetails.broker_raw_info.otpSessionId',
    ''
  );
  const { brokerClientId, brokerClientAccessToken, mobileNumber } =
    getDynamicAppConfigs().brokerConfigs;

  const requestBody = JSON.stringify({
    cuserid: brokerClientId,
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
  });

  const requestHeaders = getHeaders(mobileNumber);
  requestHeaders['custom-cookie'] = `${_.get(
    requestHeaders,
    'custom-cookie',
    ''
  )}; otpSessionId=${otpSessionId};`;
  _.assign(requestHeaders, { token: brokerClientAccessToken });

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };
  return await wrappedFetch(getRequestUri('orderBook'), parameters)
    .then((response) => response.json())
    .then((res) => (res.message == 'Ok' ? true : false))
    .catch(() => false);
};

const placeOrder = async (placeOrderConfigs) => {
  const confirmOrder = async (parameters) => {
    return wrappedFetch(getRequestUri('confirmOrder'), parameters)
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((e) => [e]);
  };

  const otpSessionId = _.get(
    window,
    'instaConstants.userDetails.broker_raw_info.otpSessionId',
    ''
  );
  const { brokerClientId, brokerClientAccessToken, mobileNumber } =
    getDynamicAppConfigs().brokerConfigs;

  const requestHeaders = getHeaders(mobileNumber);
  requestHeaders['custom-cookie'] = `${_.get(
    requestHeaders,
    'custom-cookie',
    ''
  )}; otpSessionId=${otpSessionId};`;
  _.assign(requestHeaders, { token: brokerClientAccessToken });

  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig, brokerClientId);
      const requestBody = JSON.stringify(orderDetails);
      const parameters = {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
      };

      return wrappedFetch(getRequestUri('placeOrder'), parameters)
        .then((response) => response.json())
        .then(async (res) => {
          if (res?.message === 'Ok') {
            return await confirmOrder(parameters);
          }
        })
        .catch((e) => [e]);
    })
  );
  return _.chain(formattedConfig)
    .map(({ message, data }) =>
      message === 'Ok'
        ? _.get(data, 'nestOrderNumber', '')
        : { status: 'failed' }
    )
    .value();
};

const cancelPlaceOrder = (orderConfig) => {
  const otpSessionId = _.get(
    window,
    'instaConstants.userDetails.broker_raw_info.otpSessionId',
    ''
  );
  const { brokerClientId, brokerClientAccessToken, mobileNumber } =
    getDynamicAppConfigs().brokerConfigs;

  const requestData = parseCancelOrder(orderConfig, brokerClientId);
  const requestbody = JSON.stringify(requestData);
  const requestHeaders = getHeaders(mobileNumber);
  requestHeaders['custom-cookie'] = `${_.get(
    requestHeaders,
    'custom-cookie',
    ''
  )}; otpSessionId=${otpSessionId};`;
  _.assign(requestHeaders, { token: brokerClientAccessToken });

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestbody,
  };

  return wrappedFetch(getRequestUri('cancelPlaceOrder'), parameters).then(
    (response) => response.json()
  );
};

const modifyPlaceOrder = async (placeOrderConfigs) => {
  const otpSessionId = _.get(
    window,
    'instaConstants.userDetails.broker_raw_info.otpSessionId',
    ''
  );
  const { brokerClientId, brokerClientAccessToken, mobileNumber } =
    getDynamicAppConfigs().brokerConfigs;

  const orderDetails = await parseModifyOrder(placeOrderConfigs[0], brokerClientId);
  const requestbody = JSON.stringify(orderDetails);

  const requestHeaders = getHeaders(mobileNumber);
  requestHeaders['custom-cookie'] = `${_.get(
    requestHeaders,
    'custom-cookie',
    ''
  )}; otpSessionId=${otpSessionId};`;
  _.assign(requestHeaders, { token: brokerClientAccessToken });

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestbody,
  };

  return wrappedFetch(getRequestUri('modifyOrder'), parameters)
    .then((response) => response.json())
    .then(({ data }) => _.split(data['nestOrderNumber'], ':')[1] || null)
    .catch((e) => [e]);
};

const fetchMarginCalculator = async (orderConfigs) => {
  const otpSessionId = _.get(
    window,
    'instaConstants.userDetails.broker_raw_info.otpSessionId',
    ''
  );
  const { brokerClientId, brokerClientAccessToken, mobileNumber } =
    getDynamicAppConfigs().brokerConfigs;

  const formattedResponse = await Promise.all(
    _.map(orderConfigs, async (orderConfig) => {
      const orderDetails = await parseMarginCalculator(
        orderConfig,
        brokerClientId
      );
      const requestBody = JSON.stringify(orderDetails);
      const requestHeaders = getHeaders(mobileNumber);
      requestHeaders['custom-cookie'] = `${_.get(
        requestHeaders,
        'custom-cookie',
        ''
      )}; otpSessionId=${otpSessionId};`;
      _.assign(requestHeaders, { token: brokerClientAccessToken });

      const parameters = {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
      };

      return wrappedFetch(getRequestUri('marginCalculator'), parameters)
        .then((response) => response.json())
        .then((data) => {
          return data?.data;
        })
        .catch((e) => [e.message]);
    })
  );
  const totalMarginRequired =
    _.chain(formattedResponse)
      .map(({ orderMargin }) => parseFloat(orderMargin))
      .sum()
      .value() || 0;

  const totalMarginAvailable =
    _.parseInt(formattedResponse?.[0]['availableCach']) || 0;

  return { totalMarginRequired, totalMarginAvailable };
};

const fetchOrderBook = async () => {
  const otpSessionId = _.get(
    window,
    'instaConstants.userDetails.broker_raw_info.otpSessionId',
    ''
  );
  const { brokerClientId, brokerClientAccessToken, mobileNumber } =
    getDynamicAppConfigs().brokerConfigs;

  const requestBody = JSON.stringify({
    cuserid: brokerClientId,
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
  });
  const requestHeaders = getHeaders(mobileNumber);
  requestHeaders['custom-cookie'] = `${_.get(
    requestHeaders,
    'custom-cookie',
    ''
  )}; otpSessionId=${otpSessionId};`;
  _.assign(requestHeaders, { token: brokerClientAccessToken });

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  return await wrappedFetch(getRequestUri('orderBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(result?.data?.orderBookList, 'order'),
            ['time'],
            ['desc']
          )
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const otpSessionId = _.get(
    window,
    'instaConstants.userDetails.broker_raw_info.otpSessionId',
    ''
  );
  const { brokerClientId, brokerClientAccessToken, mobileNumber } =
    getDynamicAppConfigs().brokerConfigs;
  const requestHeaders = getHeaders(mobileNumber);
  requestHeaders['custom-cookie'] = `${_.get(
    requestHeaders,
    'custom-cookie',
    ''
  )}; otpSessionId=${otpSessionId};`;
  _.assign(requestHeaders, { token: brokerClientAccessToken });

  const requestBody = JSON.stringify({
    userId: brokerClientId,
    accountId: brokerClientId,
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
  });
  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  return wrappedFetch(getRequestUri('positionBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(result?.data?.positionBookList, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchTradeBook = async () => {
  const orderBook = await fetchOrderBook();
  const tradeBook = _.get(orderBook, 'orderBookDetails', []);
  const tradeBookDetails = _.filter(tradeBook, { status: 'executed' });

  return {
    tradeBookDetails: _.compact(
      _.orderBy(tradeBookDetails, ['time'], ['desc'])
    ),
    headers: tradeTableHeaders,
  };
};

const fetchHoldingBook = () => {
  const otpSessionId = _.get(
    window,
    'instaConstants.userDetails.broker_raw_info.otpSessionId',
    ''
  );
  const { brokerClientId, brokerClientAccessToken, mobileNumber } =
    getDynamicAppConfigs().brokerConfigs;

  const requestBody = JSON.stringify({
    userId: brokerClientId,
    accountId: brokerClientId,
    productAlias:
      'NRML:NRML||CNC:CNC||SHORTSELL:SHORTSELL||MIS:MIS||CO:CO||BO:BO||MTF:MTF',
    brokerName: 'INDIABULL',
  });

  const requestHeaders = getHeaders(mobileNumber);
  requestHeaders['custom-cookie'] = `${_.get(
    requestHeaders,
    'custom-cookie',
    ''
  )}; otpSessionId=${otpSessionId};`;
  _.assign(requestHeaders, { token: brokerClientAccessToken });

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  return wrappedFetch(getRequestUri('holdings'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        holdingBookDetails: _.compact(
          await parseOrderDetails(_.get(result, 'body?.data?.portfolioList', []), 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

export {
  isValidAccessToken,
  placeOrder,
  cancelPlaceOrder,
  modifyPlaceOrder,
  fetchMarginCalculator,
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
};
